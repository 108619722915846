export default {
  created() {
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of the setTagsViewTitle function, this.$route is no longer pointing to the current page
    this.tempRoute = Object.assign({}, this.$route)
  },
  data() {
    return {
      tempRoute: {}
    }
  },
  methods: {
    setTagsViewTitle() {
      const route = Object.assign({}, this.tempRoute)
      this.$store.dispatch('updateVisitedView', route)
    }
  }
}
