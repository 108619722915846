<template lang="pug">
  .page.options-page
    actions-line
      template(v-slot:left)
        router-link.item(:to="listUrl")
          el-button(
            v-loading="loading"
            type="primary"
            icon="el-icon-back"
          ) К списку
        el-button.item(
          v-loading="loading"
          type="danger"
          icon="el-icon-delete"
          @click="openDeleteModal()"
        ) Удалить
      template(v-slot:right)
        template(v-if="hasManagePermissions")
          el-button.item(
            v-loading="loading"
            type="success"
            icon="el-icon-top"
            :disabled="!isChanged"
            @click="submitForm"
          ) {{ (loading || passwordGenerating) && !dataLoading ? 'Сохранение' : 'Сохранить' }}
        template(v-else)
          el-tooltip.item(effect="light" content="У вас нет прав для сохранения данной страницы")
            el-button(
              v-loading="loading"
              icon="el-icon-top"
            ) Сохранить
    el-form.page__container.options-page__container(ref="postForm" label-width="200px")
      .page__body(v-loading="dataLoading")
        el-row
          el-col(:span="24")
            el-form-item(label="Роль" required v-permission="['manage admin__roles']")
              el-select(v-model="postForm.role" class="filter-item" placeholder="Выберите роль" :readonly="isReadonly")
                el-option(v-for="item in roles" :key="item.id" :label="item.name | uppercaseFirst" :value="item.id" :readonly="isReadonly")

            el-form-item(label="Имя" required)
              el-input(v-model="postForm.name" :readonly="isReadonly")

            el-form-item(label="E-mail" required)
              el-input(v-model="postForm.email" :readonly="isReadonly")

            el-form-item(label="Заблокирован" required)
              el-switch(v-model="postForm.blocked")

            el-form-item(label="Сменить пароль при первом входе" required)
              el-switch(v-model="postForm.temp_password")

            el-form-item(label="Срок действия пароля" required)
              el-date-picker(type="datetime" v-model="postForm.password_expiration_date" value-format="yyyy-MM-dd HH:mm:ss" :readonly="isReadonly")
              el-button.user-profile__generate-password-button(@click="setPasswordExpirationDateToMax()" type="primary") {{ maxValidity }} дней
              .user-profile__time-left(v-if="timeLeftBeforeExpire > 0") До окончания действия пароля осталось {{ formattedTimeLeftBeforeExpire }}
              .user-profile__time-left.user-profile__time-left_error(v-if="postForm.password_expiration_date && timeLeftBeforeExpire <= 0") Пароль не действует. Задайте новый срок действия пароля.

            .user-profile__passwords(v-loading="passwordGenerating")
              el-form-item(label="Пароль" required)
                el-input.user-profile__input(ref="password" v-model="postForm.password" show-password :readonly="isReadonly")
                el-button.user-profile__generate-password-button(@click="generatePassword" type="primary") Сгенерировать пароль

              el-form-item(label="Подтвердите пароль" required)
                el-input.user-profile__input(v-model="postForm.confirmPassword" show-password :readonly="isReadonly")

            div(v-permission="['manage admin__permissions']")
              h4.condition-field__wide-title Разрешения
              .user-create-page__permissions-wrapper(v-loading="!permissionsTree.length")
                user-permissions(v-if="permissionsTree.length" v-model="postForm.permissions" :role="postForm.role" :permissionsTree="permissionsTree")

</template>

<script>
  import ActionsLine from '@/components/PagePartials/ActionsLine'
  import UserPermissions from './UserPermissions'

  import { notifyRequest, notifyErrorRequest } from '@/utils/api'
  import SaveRoutes from '@/views/Templates/SaveRoutes'
  import permission from '@/directive/permission'

  export default {
    name: 'UserProfile',
    extends: [SaveRoutes],
    directives: { permission },
    components: {
      ActionsLine,
      UserPermissions
    },
    data() {
      return {
        maxValidity: 0,
        maxValidityDate: '',
        permissionsTree: [],
        roles: [],
        postForm: {
          role: '',
          name: '',
          email: '',
          blocked: false,
          temp_password: true,
          password_expiration_date: '',
          password: '',
          confirmPassword: '',
          permissions: []
        },
        postFormJson: '',
        request: this.$route.meta.request,
        loading: true,
        dataLoading: true,
        fields: [],
        formCache: '',
        passwordGenerating: false,
        isReadonly: true
      }
    },
    mounted() {
      setTimeout(() => {
        this.isReadonly = false
      }, 500)
    },
    computed: {
      timeLeftBeforeExpire() {
        const date = new Date(this.postForm.password_expiration_date)
        return date.getTime() - Date.now()
      },
      formattedTimeLeftBeforeExpire() {
        const ms = this.timeLeftBeforeExpire
        const hours = Math.floor(ms / 1000 / 3600)
        if (hours > 48) {
          const days = Math.floor(hours / 24)
          return `дней: ${days}`
        }
        return `часов: ${hours}`
      },
      model() {
        return 'admin__users'
      },
      hasManagePermissions() {
        return this.$store.getters.permissions.includes(`manage ${this.model}`)
      },
      isChanged() {
        return this.formCache !== this.postFormJson
      },
      listUrl() {
        return '/admin/users'
      },
      lang() {
        return this.$store.getters.language
      },
      rolePermissions() {
        const role = this.roles.filter(el => el.id === this.postForm.role)[0]
        if (!role) {
          return []
        }
        const permissions = role.permissions
        if (!permissions || !Array.isArray(permissions)) {
          return []
        }
        return permissions.map(el => el.name)
      }
    },
    watch: {
      postForm: {
        deep: true,
        handler() {
          console.log('postForm updated')
          this.postFormJson = JSON.stringify(this.postForm)
        }
      }
    },
    async created() {
      const fetchedData = await this.fetchData()
      this.roles = fetchedData.roles
      // console.log(this.loading);
      for (const i in fetchedData.data) {
        this.postForm[i] = fetchedData.data[i]
      }
      this.postForm.password = ''
      this.postForm.confirmPassword = ''
      this.permissionsTree = fetchedData.permissionsTree
      this.maxValidity = fetchedData.maxValidity
      this.maxValidityDate = fetchedData.maxValidityDate
      this.postForm.permissions = this.postForm.permissions.filter(el => !this.rolePermissions.includes(el))
      this.postFormJson = JSON.stringify(this.postForm)
      this.formCache = this.postFormJson
      // this.postForm.password_expiration_date = this.postForm.password_expiration_date ? Date.parse(this.postForm.password_expiration_date) : '';
      // this.fields = fetchedData.fields;
      this.loading = false
      this.dataLoading = false
    },
    methods: {
      setPasswordExpirationDateToMax() {
        this.postForm.password_expiration_date = this.maxValidityDate
      },
      openDeleteModal() {
        const id = this.$route.params.id
        const item = JSON.parse(JSON.stringify(this.postForm))
        item.id = id
        this.$confirm(`Удалить навсегда "${item.title}" (ID: ${item.id})?`, 'Подтвердите удаление', {
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отменить',
          // type: 'warning'
        }).then(() => {
          this.deleteItem(id)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Удаление отменено'
          })
        })
      },
      async deleteItem(id) {
        const response = await notifyRequest({
          url: `users/${id}`,
          method: 'delete'
        }, 'Пользователь успешно удален')
        setTimeout(() => {
          window.location.href = this.listUrl
        }, 3000)
      },
      async generatePassword() {
        this.passwordGenerating = true
        this.postForm.password = 'Генерация пароля'
        this.postForm.confirmPassword = 'Генерация пароля'
        const response = await notifyRequest({
          url: 'generate-password',
          method: 'get'
        }, 'Пароль успешно сгенерирован и вставлен')
        const password = response.password
        this.postForm.password = password
        this.postForm.confirmPassword = password
        this.$refs.password.passwordVisible = true
        this.passwordGenerating = false
      },
      async fetchData() {
        return await notifyErrorRequest({
          url: `users/${this.$route.params.id}`,
          method: 'get'
        })
      },
      async storeData(data) {
        const response = await notifyRequest({
          url: 'users/' + this.$route.params.id,
          method: 'put',
          data: data,
        }, 'Данные пользователя успешно сохранены')
        return response
      },
      async submitForm() {
        this.loading = true
        const data = { ...this.postForm }
        await this.storeData(data)
        this.formCache = this.postFormJson
        this.loading = false
      },
    },
  }
</script>
<style lang="sass">
  @import "@/assets/sass/mixin.scss"
  .user-profile
    &__input
      max-width: 400px
    &__generate-password-button
      margin-left: 15px
    &__time-left
      &_error
        color: red
        font-weight: 700
</style>
