<template lang="pug">
  sticky.actions-line(:class-name="'sub-navbar'")
    .actions-line__row
      .actions-line__subrow.actions-line__subrow_left
        slot(name="left")
      .actions-line__subrow.actions-line__subrow_center
        slot(name="center")
      .actions-line__subrow.actions-line__subrow_right
        slot(name="right")
</template>

<script>
  import Sticky from '@/components/Sticky'

  export default {
    name: 'ActionsLine',
    components: {
      Sticky
    },
    props: {
      loading: { type: Boolean, default: false }
    },
    computed: {
      listUrl() {
        return this.$route.fullPath.replace('/create', '/list')
      }
    },
    async created() {
    },
    methods: {
      emitCreate() {
        this.$emit('create')
      }
    },
  }
</script>
<style lang="sass">
  @import "@/assets/sass/mixin.scss"
  .actions-line
    .sub-navbar
      padding-left: 12px
      padding-right: 12px
      z-index: 4
      height: auto!important
      @media(max-width: 1199px)
        padding-left: 7px
        padding-right: 7px
    &__row
      display: flex
      flex-flow: row nowrap
      justify-content: space-between
      width: 100%
    &__subrow
      &_left
        text-align: left
      // display: flex
      // flex-flow: row nowrap
      align-items: center
      &:not(:last-child)
        margin-right: 10px
    &__item, .item
      margin: 0 3px
    @media(max-width: 1199px)
      .el-button
        padding-left: 8px
        padding-right: 8px
</style>
