<template lang="pug">
  .user-permissions
    .block(v-loading="dataLoading")
      .user-permissions__head
        .user-permissions__head-name Наименование
        .user-permissions__head-title Заголовок
        .user-permissions__head-col(v-for="type in permissionTypes" :key="type") {{ type }}

      el-checkbox-group(v-model="currentValue")
        el-tree.user-permissions__tree(
          ref="permissions"
          :data="permissionsTreeInner"
          :default-expanded-keys="defaultExpanded"
          :props="props"
          :expand-on-click-node="false"
          node-key="id"
        )
          template(slot-scope="{node, data}")
            .user-permissions__item(v-if="data && data.id && data.roles")
              .user-permissions__item-col
                .user-permissions__item-name {{ data.name }}
                .user-permissions__item-title {{ data.title }}
              .user-permissions__item-types
                el-checkbox.user-permissions__item-types-checkbox(
                  v-for="type in permissionTypes"
                  :label="`${type} ${data.fullName}`"
                  :disabled="!data.types.includes(type)"
                  :key="type"
                  :class="{\
                    'user-permissions__item-types-checkbox_role-has': (data.roles[type] || []).includes(role),\
                    'user-permissions__item-types-checkbox_disabled': !data.types.includes(type),\
                    'user-permissions__item-types-checkbox_unnecessary': data.types.includes(type) && isUnnecessary(type, data)\
                  }"
                ) {{ '' }}
        //:default-expanded-keys="[2, 3]"
        //:default-checked-keys="permissionKeys(userMenuPermissions)"
</template>
<script>
  import { notifyErrorRequest, notifyRequest } from '@/utils/api'

  export default {
    name: 'UserPermissions',
    components: {
    },
    props: {
      value: { type: Array, default: () => ([]) },
      role: { type: Number, default: null },
      permissionsTree: { type: Array, default: null }
    },
    data() {
      return {
        currentValue: this.value,
        dataLoading: true,
        permissionTypes: [],
        permissionsTreeInner: [],
        defaultExpanded: [],
        props: {
          children: 'children',
          label: 'name'
        }
      }
    },
    computed: {
      lang() {
        return this.$store.getters.language
      },
    },
    async created() {
      if (!this.permissionsTree) {
        const fetchedData = await this.fetchData()
        this.permissionsTreeInner = fetchedData.data
      } else {
        this.permissionsTreeInner = JSON.parse(JSON.stringify(this.permissionsTree))
      }
      this.permissionTypes = this.$store.state.user.allPermissionTypes
      this.currentValue = Array.isArray(this.currentValue) ? this.currentValue : []
      this.defaultExpanded = this.makeDefaultExpanded(this.permissionsTreeInner)
      this.dataLoading = false
    },
    watch: {
      currentValue: {
        deep: true,
        handler() {
          this.$emit('input', this.currentValue)
        }
      },
      value: {
        deep: true,
        handler() {
          this.currentValue = this.value
        }
      }
    },
    methods: {
      async fetchData() {
        return await notifyErrorRequest({
          url: `permissions/tree`,
          method: 'get'
        })
      },
      makeDefaultExpanded(permissions) {
        return permissions.reduce((arr, el) => {
          if (el.children && el.children.length) {
            arr.push(el.id)
            arr = [...arr, ...this.makeDefaultExpanded(el.children)]
          }
          return arr
        }, [])
      },
      isUnnecessary(type, data) {
        return this.currentValue.includes(data.types[type])
      }
    },
  }
</script>
<style lang="sass">
  @import "@/assets/sass/mixin.scss"
  @import "@/assets/sass/blocks/user-permissions.sass"
</style>
